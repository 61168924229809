import React, { useEffect, useState } from 'react';
import { translateText } from './translate'; // Vertaalfunctie importeren

// Stopwoordenlijst (optioneel: kan je gebruiken om irrelevante woorden te verwijderen)
const stopwords = ['is', 'de', 'een', 'het', 'van', 'op', 'aan', 'voor', 'met', 'en', 'in'];

// Functie om leestekens te verwijderen en dubbele spaties op te schonen
const cleanText = (text) => {
  console.log("Original text before cleaning:", text);
  const cleaned = text
    .toLowerCase()
    .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "")
    .replace(/\s{2,}/g, " ");
  console.log("Cleaned text:", cleaned);
  return cleaned;
};

// Functie om te controleren of alle zoekwoorden in de beschrijving voorkomen
const matchAllWords = (description, searchWords) => {
  // Beschrijving opschonen
  const cleanedDescription = cleanText(description);
  
  // Controleren of elk woord uit de zoekterm in de beschrijving voorkomt
  return searchWords.every(word => cleanedDescription.includes(word));
};

// Functie om ervaringen te filteren die voldoen aan alle zoekwoorden
const filterExperiences = (experiences, searchWords) => {
  return experiences.filter(experience => matchAllWords(experience.description, searchWords));
};

const ExperienceList = ({ experiences, searchTerm, handleSearch }) => {
  const [translatedExperiences, setTranslatedExperiences] = useState([]); // Status voor vertaalde ervaringen
  const [userLang, setUserLang] = useState('en'); // Standaardtaal Engels als fallback
  const [loadingTranslations, setLoadingTranslations] = useState(false); // Laadstatus voor vertalingen

  // Functie om ervaringen te vertalen naar de voorkeurstaal van de gebruiker
  const translateExperiences = async (experiences, lang) => {
    setLoadingTranslations(true);
    console.log("Translating experiences:", experiences, "Language:", lang);

    if (lang !== 'nl') {
      const translated = await Promise.all(
        experiences.map(async (experience) => {
          const translatedDescription = await translateText(experience.description, lang);
          return {
            ...experience,
            description: translatedDescription,
          };
        })
      );
      console.log("Translated experiences:", translated);
      setTranslatedExperiences(translated);
    } else {
      console.log("No translation needed, original experiences used.");
      setTranslatedExperiences(experiences); 
    }

    setLoadingTranslations(false); 
  };

  // Zoek naar combinaties van woorden, ongeacht volgorde en met spaties toegevoegd
  useEffect(() => {
    const lang = navigator.language ? navigator.language.split('-')[0] : 'en';
    const supportedLanguages = ['en', 'nl', 'fr', 'de', 'es'];
    const detectedLang = supportedLanguages.includes(lang) ? lang : 'en';
    console.log("Detected language:", detectedLang);

    if (searchTerm) {
      console.log("Search term before cleaning:", searchTerm);
      const cleanedSearchTerm = cleanText(searchTerm.toLowerCase());
      const searchWords = cleanedSearchTerm.split(' ').filter(word => word.trim() !== '');
      console.log('Search words:', searchWords);

      // Vind ervaringen die overeenkomen met alle zoekwoorden
      const filteredExperiences = filterExperiences(experiences, searchWords);
      console.log('Gefilterde ervaringen:', filteredExperiences);

      translateExperiences(filteredExperiences, detectedLang); 
    }
  }, [searchTerm, experiences]);

  return (
    <>
      <div className="search-container">
        <input
          className="search-bar"
          type="text"
          placeholder="Enter your issue to search..."
          value={searchTerm}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>

      {loadingTranslations ? (
        <p>Loading translations...</p>
      ) : searchTerm && translatedExperiences.length > 0 ? (
        <div className="summary-container">
          <div className="worked-summary">
            <h2>What Worked</h2>
            <ul>
              {translatedExperiences.map((experience, index) => (
                <li key={index}>{experience.whatWorked}</li>
              ))}
            </ul>
          </div>

          <div className="didnt-work-summary">
            <h2>What Didn't Work</h2>
            <ul>
              {translatedExperiences.map((experience, index) => (
                <li key={index}>{experience.whatDidNotWork}</li>
              ))}
            </ul>
          </div>
        </div>
      ) : (
        <p>Enter a search term to find relevant experiences.</p>
      )}
    </>
  );
};

export default ExperienceList;
